<template>
  <div>
    <h1 class="pt-3 pb-4">{{ $t('resetPw.title') }}</h1>
    <h5 class="small text-white opacity-50 text-center mb-5">{{ $t('resetPw.desc') }}</h5>
    <div class="ng-untouched ng-pristine ng-invalid">
      <div class="form-group">
        <input type="id" class="ng-untouched ng-pristine ng-invalid" v-model="id"
               :placeholder="this.$i18n.t('resetPw.holderId')">
        <input type="email" class="ng-untouched ng-pristine ng-invalid" v-model="email"
               :placeholder="this.$i18n.t('resetPw.holderEmail')">
      </div>
      <div class="form-group">
        <div class="w3-bar">
          <button class="btn btn-primary w3-teal" @click="emailReset()">{{
              $t('resetPw.reset')
            }}
          </button>
        </div>
        <router-link to="/sign/login" class="btn text-white mt-4">{{
            $t('common.cancel')
          }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      id: '',
      email: '',
    }
  },
  methods: {
    emailReset() {
      const id = this.id;
      const email = this.email;
      const body = {id, email};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();
      this.$axios.post('/member/email/reset', {req}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                this.$alert(`${this.$i18n.t('resetPw.complete')}`).then(
                    () => {
                      this.$router.push({path: "/sign/login"});
                    }
                )
              } else if (res.data.code == '100') {
                this.$alert(`${this.$i18n.t('resetPw.alertConfirmInfo')}`)
              }
            }
          }
      )
    }
  }
}
</script>

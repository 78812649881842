<template>
  <div>
    <h1 class="pt-3 pb-4">{{$t('login.title')}}</h1>
    <div class="form ng-star-inserted">
      <div novalidate="" class="ng-untouched ng-pristine ng-invalid">
        <div class="form-group">
          <select class="ng-untouched ng-pristine ng-valid"
                  v-model="$i18n.locale" @change="onLocaleChange($event)">
            <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`"
                    :value="locale">
              {{ locale === 'ko' ? '한국어' : 'English' }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="ng-untouched ng-pristine ng-invalid"
            v-model="id"
            :placeholder="this.$i18n.t('login.holderId')"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="ng-untouched ng-pristine ng-invalid"
            v-model="password"
            :placeholder="this.$i18n.t('login.holderPw')"
            @keyup.enter="login()"
          />
        </div>
        <h6 class="m-0">
          <router-link to="/sign/repw" class="btn-link1 my-4">{{$t('login.forgotPw')}}</router-link>
        </h6>
        <div class="form-group">
          <button class="btn btn-primary" @click="login()">{{$t('login.login')}}</button>
          <!-- <router-link to="/dashboard"> 로그인 -->
          <!-- </router-link> -->
        </div>
        <router-link to="/sign/signup" class="btn btn-link" style="color: #fff">{{$t('login.signup')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      country: this.$store.state.locale === 'ko' ? 'KR': 'US',
      id: "",
      password: "",
      loading: false,
    };
  },
  created() {
    this.$i18n.locale = this.$store.state.locale
  },
  methods: {
    login: function () {
      const id = this.id;
      const password = this.password;
      const body = { id, password };

      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$axios.post("/member/login", { req }).then((res) => {
        if (res.status == 200) {
          if (res.data.code == "200") {
            this.loading = false;
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.token = body.token;
            this.plan = body.plan;
            this.e_date = body.e_date;
            window.localStorage.setItem("plan", body.plan);
            this.confirmLogin();
          } else if (res.data.code == "110") {
            this.loading = false;
            this.$alert(`${this.$i18n.t('login.alertEmail')}`);
          } else {
            this.loading = false;
            this.$alert(`${this.$i18n.t('login.alertConfirmInfo')}`);
          }
        }
      });
    },
    confirmLogin() {
      const token = this.token;
      const id = this.id;
      const login = true;
      const plan = this.plan;
      const e_date = this.e_date;
      const info = { token, login, id, plan, e_date };
      this.$store.dispatch("SETLOGIN", info).then(
        this.$alert(`${this.$i18n.t('login.complete')}`).then(() => {
          window.location.href = "/";
        })
      );
    },
    onLocaleChange(event) {
      this.$i18n.locale = event.target.value;
      this.$store.dispatch('changeLocale', event.target.value);
      window.location.reload(true);
    }
  },
};
</script>
